.gallery {
  height: 100%;
  @include flexbox();
  @include flex-direction(column);
}

.gallery__swiper {
  @include flex(1);
}

.gallery-item {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  height: 100%;
  overflow: hidden;
}

.gallery-item__main {
  display: block;
}
