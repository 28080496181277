@mixin media-breakpoint-down($name, $breakpoints: default) {
  @if $breakpoints == default {
    $breakpoints: $responsive;
  }
  @media (max-width: map-get($breakpoints, $name) - 1) {
    @content;
  }
}

@mixin media-breakpoint-up($name, $breakpoints: default) {
  @if $breakpoints == default {
    $breakpoints: $responsive;
  }
  @media (min-width: map-get($breakpoints, $name)) {
    @content;
  }
}
