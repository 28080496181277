.article__content {
  p.success {
    padding: map-get($spacers, 2) map-get($spacers, 3);
    background-color: rgba($green, .1);
    border: 1px solid $green;
    border-radius: map-get($base, border-radius);
  }

  p.info {
    padding: map-get($spacers, 2) map-get($spacers, 3);
    background-color: rgba($blue, .1);
    border: 1px solid $blue;
    border-radius: map-get($base, border-radius);
  }

  p.warning {
    padding: map-get($spacers, 2) map-get($spacers, 3);
    background-color: rgba($yellow, .1);
    border: 1px solid $yellow;
    border-radius: map-get($base, border-radius);
  }

  p.error {
    padding: map-get($spacers, 2) map-get($spacers, 3);
    background-color: rgba($red, .1);
    border: 1px solid $red;
    border-radius: map-get($base, border-radius);
  }
}
