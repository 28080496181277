.extensions {
  margin: map-get($spacers, 3) 0;
  @extend .d-print-none;
}

.extensions--video, .extensions--slide, .extensions--demo {
  position: relative;
  width: 100%;
  padding: 0;
  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.extensions--video {
  padding-top: percentage(315 / 560);
}

.extensions--slide {
  padding-top: percentage(487 / 599);
}

.extensions--demo {
  min-height: 340px;
  padding-top: percentage(315 / 560);
}

.extensions--audio {
  display: block;
  max-width: 100% !important;
}
