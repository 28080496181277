@mixin horizontal-rules() {
  &::before {
    display: block;
    font-size: map-get($base, font-size-h2);
    color: $text-color-l;
    text-align: center;
    letter-spacing: map-get($spacers, 4);
    content: "...";
  }
}

.horizontal-rules {
  @include horizontal-rules();
}
