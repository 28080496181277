.layout--home {
  .pagination {
    margin: map-get($spacers, 4) 0;
  }
  .pagination__menu {
    max-width: 100%;
    @include overflow(auto);
  }
  .pagination__omit {
    color: $text-color-l;
  }
  .items {
    margin-top: map-get($spacers, 4) * 1.5;
  }
}
