.search {
  @include overflow(auto);

}
.search--google-custom-search-engine {
  .main {
    padding-top: map-get($spacers, 4);
    padding-bottom: map-get($spacers, 4);
    @include media-breakpoint-down(md) {
      position: absolute;
      padding: 0;
    }
  }
}

.search__header {
  margin-top: map-get($spacers, 4);
  font-size: map-get($base, font-size-h1);
  font-weight: map-get($base, font-weight-bold);
  color: $text-color-d;
  .search--light & {
    color: $text-color-theme-light-d;
  }
  .search--dark & {
    color: $text-color-theme-dark-d;
  }
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.search-bar {
  @include flexbox();
  margin: map-get($spacers, 3) 0 map-get($spacers, 4) 0;
}

.search-box {
  position: relative;
  width: 100%;
  max-width: 22rem;
  @include media-breakpoint-down(md) {
    width: 100%;
    max-width: none;
  }
  & > input {
    display: inline-block;
    width: 100%;
    height: $button-height-lg;
    padding: 0 2rem;
    margin: 0;
    line-height: 1 !important;
    color: $text-color;
    background-color: transparent;
    border: 2px solid $border-color;
    border-radius: map-get($button, pill-radius);
    -webkit-appearance: none; /* fix iOS don't display box-shadow properly */
    @include transition(box-shadow map-get($animation, duration) map-get($animation, timing-function));
    @include focus {
      box-shadow: 0 0 0 2px rgba($border-color, .4);
    }
    .search--light & {
      color: $text-color-theme-light;
      border-color: $text-color-theme-light;
      @include focus {
        box-shadow: 0 0 0 2px rgba($text-color-theme-light, .4);
      }
    }
    .search--dark & {
      color: $text-color-theme-dark;
      border-color: $text-color-theme-dark;
      @include focus {
        box-shadow: 0 0 0 2px rgba($text-color-theme-dark, .4);
      }
    }
  }
  & > .search-box__icon-search {
    color: $text-color-l;
    .search--light & {
      color: $text-color-theme-light-l;
    }
    .search--dark & {
      color: $text-color-theme-dark-l;
    }
  }
  & > .search-box__icon-clear {
    & > a {
      @include link-colors($text-color);
      .search--light & {
        @include link-colors($text-color-theme-light);
      }
      .search--dark & {
        @include link-colors($text-color-theme-dark);
      }
      cursor: pointer;
    }
  }
  & > .search-box__icon-search, & > .search-box__icon-clear {
    position: absolute;
    width: $button-height-lg;
    height: $button-height-lg;
    line-height: $button-height-lg;
    text-align: center;
    vertical-align: middle;
  }
  &.not-empty > .search-box__icon-clear {
    display: block;
  }
  & > .search-box__icon-clear {
    top: 0;
    right: 0;
    display: none;
  }
  & > .search-box__icon-search {
    top: 0;
    left: 0;
  }
}

.search__cancel {
  margin-left: map-get($spacers, 2);
  font-weight: map-get($base, font-weight-bold);
  white-space: nowrap;
}

.search-result {
  margin: map-get($spacers, 4) 0;
  font-size: map-get($base, font-size-sm);
  line-height: map-get($base, line-height-sm);
}

.search-result__header {
  margin: map-get($spacers, 3) 0 map-get($spacers, 2) 0;
  font-size: map-get($base, font-size-lg);
  font-weight: map-get($base, font-weight-bold);
  color: $text-color-l;
  text-transform: uppercase;
  .search--light & {
    color: $text-color-theme-light-l;
  }
  .search--dark & {
    color: $text-color-theme-dark-l;
  }
}

.search-result__item {
  list-style-type: none;
  a {
    padding: map-get($spacers, 1) map-get($spacers, 3);
    @include transition(none);
    @include clickable($text-color, transparent, $text-color-3, $main-color-3);
    .search--light & {
      @include clickable($text-color-theme-light, transparent, $text-color-theme-dark, $main-color-theme-light);
    }
    .search--dark & {
      @include clickable($text-color-theme-dark, transparent, $text-color-theme-light, $main-color-theme-dark);
    }
  }
  &.active {
    a {
      @include plain() {
        color: $text-color-3;
        background-color: $main-color-3;
        .search--light & {
          color: $text-color-theme-dark;
          background-color: $main-color-theme-light;
        }
        .search--dark & {
          color: $text-color-theme-light;
          background-color: $main-color-theme-dark;
        }
      }
      @include active() {
        @include transition(map-get($clickable, transition));
      }
    }
  }
}

// google search
.gsc-control-cse {
  *,
  ::before,
  ::after {
    box-sizing: initial;
  }
}
