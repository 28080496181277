.article-list {
  .item__meta {
    padding: 0 map-get($spacers, 3) 0 0;
    font-family: map-get($base, font-family-code);
    font-size: map-get($base, font-size-sm);
    white-space: nowrap;
  }
  &.grid--sm {
    .card__header {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.article-list__group-header {
  margin-top: map-get($spacers, 3);
}
