.article__footer {
  margin: map-get($spacers, 4) 0;
  font-size: map-get($base, font-size-sm);
}

.article__license, .article__subscribe {
  a {
    @include link-colors($text-color, $main-color-1);
  }
}

.article__license {
  color: $text-color-l;
  img {
    height: map-get($base, font-size) * 1.6;
  }
}
